import * as React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"

export default class NotFoundPage extends React.Component {

    componentDidMount(){
        window.location.href = "/services/marketing";
    }


    render() {
        return (
            <Layout>
                <SEO title="Redirecting..." />
                <section style={{
                    height: '100vh',
                    paddingTop: '10em'
                }} className="container">
                    <h1>Redirecting you to the proper page...</h1>
                </section>
            </Layout>
        )
    }
}